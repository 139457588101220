import React, { useEffect } from "react";

function RequestCallback() {
  useEffect(() => {
    // Dynamically load the script once the component mounts (in the browser)
    const script = document.createElement("script");
    script.src = "https://server.fillout.com/embed/v1/";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <>
      {/* For Full screen */}
      <div style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0 }}>
        <div
          data-fillout-id={process.env.FILLOUT_FORM_ID}
          data-fillout-embed-type="fullscreen"
          style={{ width: "100%", height: "100%" }}
          data-fillout-inherit-parameters
        ></div>
      </div>
    </>
  );
}

export default RequestCallback;
